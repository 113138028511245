.image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.openable-image-container {
  max-width: 100%;
  height: 100%;
}
.image-container {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}


.image-container {
  transition: 0.1s ease-in-out;
}

.image-container:hover {
  transform: scale(1.1);
}
