body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Raleway', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: 'SegoeTVBold'; /*Can be any text*/
  src: local('SegoeTVBold'), url('./assets/customFonts/SegoeTVBold.TTF') format('truetype');
}
