.carousel {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 90vw;
  height: 70vh;
  margin-top: 3vh;
  background: black;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 10px;
  color: white;
  width: 20vw;
  height: 50vh;
}

.carousel-item-mobile {
  width: 60vw;
}

.buttons {
  color: white;
  width: 100%;
  font-size: 4rem;
  font-weight: lighter;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
}

.previous,
.next {
  padding: 0 2%;
}

.previous:hover,
.next:hover {
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 2%;
}
