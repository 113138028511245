.personal-information-external-wrapper {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.personal-information-external-wrapper-mobile {
  height: 100vh;
  padding: 0 !important;
  justify-content: space-around;
}

.personal-information-external-wrapper > h1 {
  color: white;
  padding: 0 20px 30px 20px;
  border-bottom: solid 0.5px white;
  font-weight: lighter;
}

.copyright {
  display: flex;
  width: 100vw;
  justify-content: flex-end;
  margin: 5% 5% 0 0;
}
.github-link {
  text-decoration: none;
  color: white;
}

.personal-information-internal-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.personal-information-internal-wrapper-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 40%;
}
.contact-information {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: solid 0.5px rgba(128, 128, 128, 0.5);
}

.contact-information-mobile {
  width: 100vw !important;
  border-right: solid 0 rgba(128, 128, 128, 0.5) !important;
}

.contact-information > div {
  flex: 1;
}

.contact-information > div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.contact-information > div > a > p {
  margin-left: 2%;
}

.contact-information > div > a:link,
.contact-information > div > a:visited {
  text-decoration: none;
  color: white;
}

.extra-contact-information {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50vw;
}

.extra-contact-information > a {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.extra-contact-information-mobile > a {
  width: auto;
}

.extra-contact-information > a:link,
.extra-contact-information > a:visited {
  text-decoration: none;
  color: white;
}
