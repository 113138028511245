.home-body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: black;
  overflow-y: scroll;
}
.background-web {
  background: url('../../assets/imgs/backgroundImg_web.jpeg') no-repeat center top fixed;
  background-size: cover;
  color: white;
}

.background-mobile {
  background: url('../../assets/imgs/backgroundImg_mobile.jpeg') no-repeat center top fixed;
  background-size: 80vh;
  color: white;
  text-align: center;
  background-color: black;
}

.name-profession-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.name-profession-innercontainer {
  font-family: 'light';
  display: flex;
  flex-direction: column;
  width: 336.5px;
  justify-content: center;
  align-items: flex-start;
  border-bottom: solid 5px #682a32;
  padding-bottom: 1%;
}

.name-profession-innercontainer-mobile {
  font-family: 'light';
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 5% 0 5% 10%;
  margin-bottom: 20%;
  background-color: rgba(68, 17, 14, 0.5);
}

.name-profession-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
}

.name-profession-container > * {
  margin-left: 3%;
}
