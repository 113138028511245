.vita-text-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vita-text {
  color: white;
  white-space: pre-wrap;
  text-align: justify;
}

.vita-text-container-mobile {
  width: 90%;
  height: auto;
  padding: 5%;
  overflow-y: auto;
  text-align: justify;
}
.vita-text-mobile {
  color: #fff;
  white-space: pre-wrap;
  font-family: 'raleway';
  height: 700px;
  margin-bottom: 20px;
}

.vita-external-container {
  background-color: rgb(44, 49, 55);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vita-external-container > h1 {
  color: white;
  padding: 30px 20px;
  border-bottom: solid 0.5px white;
  font-weight: lighter;
}
.vita-internal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5%;
}

.vita-internal-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vita-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vita-img-container > img {
  width: 30vw;
  transition: 0.5s ease-in-out;
}

.vita-img-container > img:hover {
  transform: scale(1.2);
}

.vita-img-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}

.vita-img-container-mobile > img {
  height: 40vh;
  transition: 0.5s ease-in-out;
  object-fit: cover;
}
/* Add these styles to your stylesheet */
.language-toggle-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.language-toggle-button {
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-toggle-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

/* Style the button for mobile */
@media (max-width: 1224px) {
  .language-toggle-container {
    margin-top: 20px;
  }

  .language-toggle-button {
    padding: 10px 15px;
  }
}
