.single-appointment-wrapper {
  padding: 1%;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: rgb(70, 89, 100);
  cursor: pointer;
  overflow-x: hidden;
}

.shownAppointments-toggle-button {
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 15px;
  margin-top: 20px;
}

.shownAppointments-toggle-button:hover {
  background-color: #0056b3; /* Darker blue color on hover */
}

.more-info > a:visited,
.more-info > a:link {
  text-decoration: none;
  color: rgb(70, 89, 100);
}

.more-info {
  background-color: white;
  border-radius: 10%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: medium;
  width: 100%;
  border: none;
}

.date {
  color: gray;
  margin-left: 5%;
}

.date > h2 > span {
  font-weight: lighter;
}

.icon-title-address {
  display: flex;
  justify-content: flex-start;
  transition: 0.5s ease-in-out;
  width: 60%;
}

.title-address {
  transition: 0.5s ease-in-out;
  margin-left: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion:hover .icon-title-address {
  transform: translateX(-40%);
}

.location {
  font-weight: lighter;
}

.accordion:hover .location {
  display: none;
}

.accordion:hover .title-address {
  font-size: 1.2rem;
  font-weight: bolder;
}

.accordion:hover {
  border-top: 2px solid rgb(70, 89, 100);
  border-bottom: 2px solid rgb(70, 89, 100);
}

.accordion {
  width: 100%;
  cursor: pointer;
}

.appointments-internal-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.appointments-external-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
  color: rgb(44, 49, 55);
}
.appointments-external-wrapper > h1 {
  color: rgb(44, 49, 55);
  padding: 30px 20px;
  border-bottom: solid 0.5px rgb(44, 49, 55);
  font-weight: lighter;
}

.icon > img {
  width: 5vw;
  height: 5vw;
  border-radius: 50px;
}

/*mobile*/
.more-info-mobile {
  height: 7vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.more-info-mobile > a:visited,
.more-info-mobile > a:link {
  text-decoration: none;
  color: rgb(70, 89, 100);
}

.more-info-mobile > a {
  font-size: large;
  font-weight: lighter;
}

.event-bg-mobile-wrapper {
  display: flex;
  flex-direction: column;
}

.event-bg-mobile {
  height: 30vh;
  width: 80vw;
  max-width: 100%;
}

.date-title-mobile {
  color: rgb(70, 89, 100);
  bottom: 0;
  left: 5%;
  text-align: center;
}

.indented {
  padding-left: 5%;
}
.date-title-mobile > h2 > span {
  font-weight: lighter;
}

.accordion-moreButton-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3%;
}

.accordion-details {
  padding: 0;
}
