.gallery {
    background-color: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.gallery > h1 {
    color: white;
    padding: 30px 20px;
    border-bottom: solid 0.5px white;
    font-weight: lighter;
}
