.gallery {
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.gallery > h1 {
  color: white;
  padding: 30px 20px;
  border-bottom: solid 0.5px white;
  font-weight: lighter;
}

.opened-image-container {
  width: 100%;
  height: 100%;
  background-color: rgba(161, 161, 161, 0.62);
  display: flex;
  justify-content: center;
  align-items: center;
}

.opened-image-container-mobile {
  width: 100%;
  height: 100%;
  background-color: rgba(161, 161, 161, 0.62);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-internal-container > img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid white;
  border-radius: 10px;
}

.image-internal-container {
  height: 80%;
  width: 100%;
  object-fit: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
}

.image-internal-container-mobile {
  height: 70vh;
  width: 100vw;
}

.description {
  text-align: left;
  color: white;
  width: 30%;
}

.close-image {
  position: absolute;
  color: white;
  cursor: pointer;
  right: 0;
  margin-bottom: 80vh;
  font-size: xxx-large;
  font-weight: lighter;
  margin-right: 1%;
  margin-top: 2%;
}

.gallery-container {
  display: flex;
  justify-content: center;
  background-color: #dbdbdb;
  align-items: center;
  height: 100vh;
}

.description-mobile {
  text-align: center;
  color: white;
  width: 100%;
}

.description-mobile > h1 > span {
  font-size: large;
  font-weight: normal;
}

.return-button {
  position: absolute;
  z-index: 10;
  color: #465964;
  font-size: xxx-large;
  font-weight: 300;
  left: 0;
  top: 0;
  margin: 1% 5%;
  cursor: pointer;
}
