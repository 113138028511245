.media-container {
  background-color: rgb(44, 49, 55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.media-container > h1 {
  color: white;
  padding: 30px 20px;
  border-bottom: solid 0.5px white;
  font-weight: lighter;
}

.media-internal-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videos-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 5% 0;
}

.single-video-wrapper {
  width: 40%;
  margin: 2%;
  height: auto;
  border: 0.5px solid rgba(255, 255, 242, 0.49);
  box-shadow: white;
}

.single-video-wrapper-mobile {
  width: auto;
  margin: 2%;
  height: auto;
  border: 0.5px solid rgba(255, 255, 242, 0.49);
  box-shadow: white;
}

.audios-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  padding: 0 0 5% 0;
}

.audios-wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #f2f2f2;
  height: 15vh;
  width: 30%;
  padding: 3% 0;
  gap: 4%;
}

.audios-wrapper-mobile > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f2f2f2;
  padding: 3% 0;
}

.audio-performers {
  font-weight: lighter;
}

.media-internal-container-mobile {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videos-wrapper-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.audios-wrapper-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  margin: 5%;
}


